<style lang="scss" scoped>
  .hb-form-input:deep(.v-icon) {
    font-size: 2.5rem;
    top: -0.375rem;
    @media only screen and (min-width: 960px) {
      top: 0;
    }
  }
</style>

<template>
  <TextInput
    ref="el"
    :model-value="modelValue"
    prepend-inner-icon="mdi-numeric-positive-1"
    persistent-hint
    hint="Message and data rates may apply"
    placeholder="___-___-____"
    label="Phone Number"
    type="tel"
    :rules="phoneRules"
    @keyup="onChangePhone"
  />
</template>

<script setup>
import { AsYouType } from 'libphonenumber-js';
import { validatePhone } from '@/utils/validators';

defineProps({
  modelValue: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const el = ref();

function focus () {
  el.value.focus();
}

defineExpose({
  focus,
});

function onChangePhone (e) {
  const phone = new AsYouType('US').input(e.target.value);
  emit('update:modelValue', phone);
}

const phoneRules = [
  value => value ? true : 'Phone required',
  value => validatePhone(value) ? true : 'Invalid phone',
];

</script>
